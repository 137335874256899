var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "关键字搜索：" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.queryParams.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "keyword", $$v)
                      },
                      expression: "queryParams.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.type,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "type", $$v)
                        },
                        expression: "queryParams.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "待上架", value: 1 }
                      }),
                      _c("el-option", {
                        key: "2",
                        attrs: { label: "上架", value: 2 }
                      }),
                      _c("el-option", {
                        key: "3",
                        attrs: { label: "下架", value: 3 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
          _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
          _c("el-table-column", {
            attrs: { prop: "cover", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: scope.row.cover, fit: "contain" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type === 1
                      ? [_vm._v("平台直链")]
                      : scope.row.type === 2
                      ? [_vm._v("聚合商城")]
                      : scope.row.type === 3
                      ? [_vm._v("互动商城")]
                      : [_vm._v("未知")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "startTime", label: "推广时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.startTime || scope.row.endTime
                      ? [
                          scope.row.startTime
                            ? [
                                _vm._v(
                                  _vm._s(scope.row.startTime.substr(0, 10))
                                )
                              ]
                            : [_vm._v("-")],
                          _vm._v(" ~ "),
                          scope.row.endTime
                            ? [_vm._v(_vm._s(scope.row.endTime.substr(0, 10)))]
                            : [_vm._v("-")]
                        ]
                      : [_vm._v("长期有效")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "links", label: "流量场景" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        on: {
                          click: function($event) {
                            return _vm.showSceneList(
                              scope.row.type,
                              scope.row.links
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.links.length))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? [_vm._v("上架")]
                      : scope.row.status === 0
                      ? [_vm._v("待上架")]
                      : scope.row.status === -1
                      ? [_vm._v("下架")]
                      : [_vm._v("未知")]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleStatusUpdate(
                              scope.$index,
                              scope.row
                            )
                          }
                        }
                      },
                      [
                        scope.row.status === 1
                          ? [_vm._v("下架")]
                          : [_vm._v("上架")]
                      ],
                      2
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-sizes": [20, 50, 100, 200],
          "page-size": _vm.queryParams.page_size,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total
        },
        on: {
          "size-change": _vm.requestData,
          "current-change": _vm.requestData,
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推广资源修改",
            visible: _vm.dialogNewVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogNewVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "newForm",
              attrs: {
                model: _vm.newForm,
                rules: _vm.rules,
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "name", $$v)
                      },
                      expression: "newForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片", prop: "cover" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleCoverSuccess
                      }
                    },
                    [
                      _vm.newForm.cover
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.newForm.cover }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.newForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "type", $$v)
                        },
                        expression: "newForm.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c(
                        "el-radio",
                        { key: item.value, attrs: { label: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.newForm.type === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "平台", prop: "platform" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.newForm.platform,
                            callback: function($$v) {
                              _vm.$set(_vm.newForm, "platform", $$v)
                            },
                            expression: "newForm.platform"
                          }
                        },
                        [
                          _c("el-option", { attrs: { value: 0, label: "无" } }),
                          _vm._l(_vm.platformOptions, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "活动介绍", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.newForm.desc,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "desc", $$v)
                      },
                      expression: "newForm.desc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推广说明", prop: "description" } },
                [
                  _c("editor", {
                    model: {
                      value: _vm.newForm.description,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "description", $$v)
                      },
                      expression: "newForm.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报", prop: "poster" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handlePosterSuccess
                      }
                    },
                    [
                      _vm.newForm.poster
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.newForm.poster }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "佣金", prop: "commission_rate" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.commission_rate,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "commission_rate", $$v)
                      },
                      expression: "newForm.commission_rate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推广时间" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "开始时间",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.newForm.start_time,
                          callback: function($$v) {
                            _vm.$set(_vm.newForm, "start_time", $$v)
                          },
                          expression: "newForm.start_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "line",
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 1 }
                    },
                    [_vm._v("-")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "结束时间",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.newForm.end_time,
                          callback: function($$v) {
                            _vm.$set(_vm.newForm, "end_time", $$v)
                          },
                          expression: "newForm.end_time"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签", prop: "category" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": ""
                      },
                      model: {
                        value: _vm.newForm.category,
                        callback: function($$v) {
                          _vm.$set(_vm.newForm, "category", $$v)
                        },
                        expression: "newForm.category"
                      }
                    },
                    _vm._l([], function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结算周期", prop: "settle_period" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.settle_period,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "settle_period", $$v)
                      },
                      expression: "newForm.settle_period"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单数据", prop: "order_data" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.order_data,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "order_data", $$v)
                      },
                      expression: "newForm.order_data"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推广方式", prop: "promotion_way" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newForm.promotion_way,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "promotion_way", $$v)
                      },
                      expression: "newForm.promotion_way"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.newForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.newForm, "sort", _vm._n($$v))
                      },
                      expression: "newForm.sort"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitting },
                  on: {
                    click: function($event) {
                      return _vm.handleSave("newForm")
                    }
                  }
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogNewVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "流量场景",
            visible: _vm.dialogSceneVisible,
            width: "60%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSceneVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: {
                data: _vm.sceneData,
                border: "",
                height: "300",
                stripe: "",
                "tooltip-effect": "dark"
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "场景", prop: "scene" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.scene === 1
                          ? [_vm._v("微信")]
                          : scope.row.scene === 2
                          ? [_vm._v("支付宝")]
                          : scope.row.scene === 3
                          ? [_vm._v("APP")]
                          : scope.row.scene === 4
                          ? [_vm._v("WAP")]
                          : [_vm._v("未知")]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "H5", prop: "url" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.resourceType === 1
                          ? [
                              scope.row.url
                                ? [_vm._v("支持")]
                                : [_vm._v("不支持")]
                            ]
                          : [
                              _vm._v(
                                " " + _vm._s(scope.row.url || "不支持") + " "
                              )
                            ]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "小程序", prop: "mpAppid" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.mpAppid && scope.row.mpPath
                          ? [
                              _vm.resourceType === 1
                                ? [_vm._v("支持")]
                                : [
                                    _vm._v(
                                      " 原始ID: " +
                                        _vm._s(scope.row.mpOriginid || "-")
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " appid: " + _vm._s(scope.row.mpAppid)
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " path: " + _vm._s(scope.row.mpPath) + " "
                                    )
                                  ]
                            ]
                          : [_vm._v("不支持")]
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "deeplink", prop: "deeplink" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.resourceType === 1
                          ? [
                              scope.row.deeplink
                                ? [_vm._v("支持")]
                                : [_vm._v("不支持")]
                            ]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.deeplink || "不支持") +
                                  " "
                              )
                            ]
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }