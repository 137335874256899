import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { deletePromotionResource, getPromotionResourceList, updatePromotionResource, updatePromotionResourceStatus } from '@/api/promotion_resource';
import { getPlatformList } from '@/api/platform';
import Editor from "@/components/Tinymce";
export default {
  name: "promotionResources",
  components: {
    Editor: Editor
  },
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {
        keyword: "",
        type: null,
        status: null,
        page: 1,
        page_size: 20
      },
      total: 0,
      imageAction: process.env.VUE_APP_BASE_API + '/fileUploadAndDownload/upload',
      typeOptions: [{
        label: "平台直链",
        value: 1
      }, {
        label: "聚合商城",
        value: 2
      }, {
        label: "互动商城",
        value: 3
      }],
      platformOptions: [],
      formLabelWidth: '120px',
      dialogNewVisible: false,
      submitting: false,
      editIndex: '',
      newForm: {
        id: 0,
        name: "",
        cover: "",
        type: 1,
        desc: "",
        description: "",
        poster: "",
        platform: 0,
        commission_rate: "",
        start_time: "",
        end_time: "",
        category: [],
        settle_period: "",
        order_data: "",
        promotion_way: "",
        sort: 0
      },
      rules: {
        name: [{
          required: true,
          message: '名称 必填'
        }],
        cover: [{
          required: true,
          message: '图片 必填'
        }],
        type: [{
          required: true,
          message: '类型 必填'
        }],
        desc: [{
          required: true,
          message: '活动介绍 必填'
        }],
        description: [{
          required: true,
          message: '推广说明 必填'
        }],
        poster: [{
          required: true,
          message: '海报 必填'
        }],
        commission_rate: [{
          required: true,
          message: '佣金 必填'
        }],
        settle_period: [{
          required: true,
          message: '结算周期 必填'
        }],
        order_data: [{
          required: true,
          message: '订单数据 必填'
        }],
        promotion_way: [{
          required: true,
          message: '推广方式 必填'
        }],
        sort: [{
          required: true,
          message: '排序 必填'
        }]
      },
      dialogSceneVisible: false,
      resourceType: 0,
      sceneData: []
    };
  },
  created: function created() {
    var _this = this;

    this.requestData();
    getPlatformList({
      page: 1,
      pageSize: -1
    }).then(function (res) {
      _this.platformOptions = res.data.list;
    });
  },
  methods: {
    requestData: function requestData() {
      var _this2 = this;

      this.loading = true;
      getPromotionResourceList(_objectSpread({}, this.queryParams)).then(function (res) {
        if (res.code === 0) {
          _this2.loading = false;
          _this2.data = res.data.list;
          _this2.total = res.data.total;
        }
      });
    },
    imageBeforeUpload: function imageBeforeUpload(file) {
      var isRightSize = file.size / 1024 / 1024 < 2;

      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB');
      }

      return isRightSize;
    },
    handleCoverSuccess: function handleCoverSuccess(res) {
      this.newForm.cover = res.data.file.url;
    },
    handlePosterSuccess: function handlePosterSuccess(res) {
      this.newForm.poster = res.data.file.url;
    },
    showSceneList: function showSceneList(type, links) {
      this.dialogSceneVisible = true;
      this.resourceType = type;
      this.sceneData = links;
    },
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      this.$confirm('您确定要删除这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deletePromotionResource({
          id: row.id
        }).then(function (res) {
          if (res.code === 0) {
            _this3.$message.success('删除成功');

            _this3.data.splice(index, 1);
          }
        });
      }).catch(function () {
        _this3.$message.info('已取消删除');
      });
    },
    handleStatusUpdate: function handleStatusUpdate(index, row) {
      var _this4 = this;

      var status = 1;
      var statusText = "上架";

      if (row.status === 1) {
        status = -1;
        statusText = "下架";
      }

      this.$confirm('您确定要' + statusText + '该推广资源吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        updatePromotionResourceStatus({
          id: row.id,
          status: status
        }).then(function (res) {
          if (res.code === 0) {
            _this4.$message.success(statusText + '成功');

            var arr = _this4.data.slice();

            arr[index].status = status;
            _this4.data = arr;
          }
        });
      }).catch(function () {
        _this4.$message.info('已取消操作');
      });
    },
    handleEdit: function handleEdit(row) {
      if (row) {
        this.newForm = {
          id: row.id,
          name: row.name,
          cover: row.cover,
          type: row.type,
          desc: row.desc,
          description: row.description,
          poster: row.poster,
          platform: row.platform,
          commission_rate: row.commissionRate,
          start_time: row.startTime ? row.startTime.substr(0, 10) : "",
          end_time: row.endTime ? row.endTime.substr(0, 10) : "",
          category: JSON.parse(row.category),
          settle_period: row.settlePeriod,
          order_data: row.orderData,
          promotion_way: row.promotionWay,
          sort: row.sort
        };
      } else {
        this.newForm = {
          id: 0,
          name: "",
          cover: "",
          type: 1,
          desc: "",
          description: "",
          poster: "",
          platform: 0,
          commission_rate: "",
          start_time: "",
          end_time: "",
          category: [],
          settle_period: "",
          order_data: "",
          promotion_way: "",
          sort: 0
        };
      }

      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this5 = this;

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var form = _objectSpread({}, _this5.newForm);

          if (form.type === 1 && form.platform <= 0) {
            _this5.submitting = false;
            return _this5.$message.warning('请选择所属平台');
          } // form.commission_rate = Number(form.commission_rate);
          // if (isNaN(form.commission_rate)) {
          //     this.submitting = false
          //     return this.$message.error('佣金不合法')
          // }


          if (form.end_time && form.end_time.length > 0) {
            if (form.start_time && form.start_time.length > 0) {
              if (form.end_time < form.start_time) {
                _this5.submitting = false;
                return _this5.$message.error('结束时间要大于等于开始时间');
              }
            }
          }

          if (_this5.newForm.id > 0) {
            updatePromotionResource(form).then(function (res) {
              _this5.submitting = false;

              if (res.code === 0) {
                _this5.$message.success('修改成功');

                _this5.dialogNewVisible = false;

                _this5.requestData();
              }
            }).catch(function () {
              _this5.submitting = false;
            });
          } else {
            _this5.submitting = false;

            _this5.$message.error('暂不支持新增');
          }
        } else {
          _this5.submitting = false;
        }
      });
    }
  }
};